.App {
  text-align: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

body {
  background-color: #223240;
}



